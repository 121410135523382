<template>
  <div v-if="data">
    <div>You've been sent a payment link for {{ displayAmount(data.amount) }} from {{ data.siteDisplayName || data.legalEntityName }}.</div>
    <div>
      <v-checkbox v-model="termsAccepted" style="display: inline-block" />
      <span style="position: relative; bottom: 45px">
        <span @click="termsAccepted = !termsAccepted">I have read and agreed with {{ data.siteDisplayName || data.legalEntityName }}&nbsp;</span>
        <a :href="data.termsOfServiceUrl" target="_blank" rel="noopener noreferrer" class="terms">terms and conditions</a>
        <span @click="termsAccepted = !termsAccepted">&nbsp;for using this service.</span>
      </span>
    </div>
    <div class="text-center">
      <v-btn :href="data.internalUrl" :disabled="!termsAccepted" size="x-large" class="font-weight-bold text-primary">Pay now</v-btn>
    </div>
  </div>

  <div v-else-if="error">
    <v-alert :color="(errorIsAnActualError ? 'red' : 'green') + ' lighten-2'" :type="errorIsAnActualError ? 'error' : 'success'" dark>
      <span class="font-weight-bold">{{ error.error }}</span
      ><br />
      <span v-if="errorIsAnActualError">Please refresh or contact <a href="mailto:help@yetipay.me">help@yetipay.me</a> if the issue persists.</span>
    </v-alert>
  </div>

  <div v-else class="text-center">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import BigNumber from 'bignumber.js';
import { useFetchLinkData } from '@/composables/useFetchLinkData';

const termsAccepted = ref(false);
const { data, error } = useFetchLinkData();

const errorIsAnActualError = computed(() => {
  if (!error) {
    return false;
  }
  if (error.value?.error === 'Payment link already paid') {
    return false;
  }
  return true;
});

const displayAmount = (amount: { value: number; currency: string }) => {
  return new BigNumber(amount.value).dividedBy(100).toNumber().toLocaleString('en-UK', {
    style: 'currency',
    currency: amount.currency,
  });
};
</script>

<style scoped>
.terms {
  color: #fff;
}
.terms:hover {
  color: #e34696;
}
</style>
