import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

const BASE_URL = import.meta.env.VITE_MERCHANT_SERVICE_BASE_URL;

export function useFetchLinkData() {
  const route = useRoute();

  const data = ref<PaymentLink | undefined>(undefined);
  const error = ref<Error | undefined>(undefined);

  onMounted(() => {
    const linkId = route.params.linkId as string;

    axios
      .get(`${BASE_URL}/v1/payment-links/${linkId}`)
      .then((response) => {
        data.value = response.data.data;
      })
      .catch((err) => {
        error.value = err?.response?.data;
      });
  });

  return { data, error };
}

export interface PaymentLink {
  legalEntityName: string;
  siteDisplayName?: string;
  amount: {
    value: number;
    currency: string;
  };
  termsOfServiceUrl: string;
  internalUrl: string;
}

export interface Error {
  error: string;
}
