import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/:linkId',
    component: () => import('@/layouts/default/View.vue'),
    children: [
      {
        path: '',
        name: 'Landing',
        component: () => import(/* webpackChunkName: "Landing" */ '@/views/Landing.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  if (!to.name) {
    window.location.href = 'https://yetipay.me';
  }
});

export default router;
